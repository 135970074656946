$(document).ready(function () {

  // Bootstrap Multi-Item Carousel edit
  $('#carouselExample').on('slide.bs.carousel', function (e) {

    var $e = $(e.relatedTarget);
    var idx = $e.index();
    var itemsPerSlide = 4;
    var totalItems = $('.carousel-item').length;
    
    if (idx >= totalItems-(itemsPerSlide-1)) {
        var it = itemsPerSlide - (totalItems - idx);
        for (var i=0; i<it; i++) {
            // append slides to end
            if (e.direction=="left") {
                $('.carousel-item').eq(i).appendTo('.carousel-inner');
            }
            else {
                $('.carousel-item').eq(0).appendTo('.carousel-inner');
            }
        }
    }
});


// Make the navigation fill or transparent depending on scroll position
$(window).scroll(function(){
    if ($(window).scrollTop() >= 30) {
        $('.header--navigation').addClass('fill');
    }
    else {
        $('.header--navigation').removeClass('fill');
    }
    if ($(window).scrollTop() >= 200) {
        $('.header--navigation').addClass('logo');
    }
    else {
        $('.header--navigation').removeClass('logo');
    }
});

// Initialize all popovers
$(function () {
    $('[data-toggle="popover"]').popover({
      html: true
    }
    )
  })

  // End of document ready function
});
